const colors = {
  black: '#000000',
  dark: '#22577e',
  link: '#f4c679',
  primary: '#f6f2d4',
  secondary: '#95d1cc',
  third: '#5584ac',
  white: '#ffffff',
};

export default colors;
