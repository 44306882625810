import colors from './colors';

const mainTheme = {
  palette: {
    colors,
  },
};

export type ThemeType = typeof mainTheme;

export default mainTheme;
